export const products = [
    { product: "CHINCHIN", price: 1125.00 },
    { product: "GROUNDNUT", price: 1440.00 },
    { product: "KULIKULI", price: 1700.00 },
    { product: "CHIPS", price: 1500.00 },
    { product: "VILLIGER ROBURSTO", price: 9375.00 },
    { product: "ROTHMANS", price: 700.00 },
    { product: "DUNHILL AND SWITCH", price: 1300.00 },
    { product: "BENSON", price: 1250.00 },
    { product: "EDGE", price: 1000.00 },
    { product: "ESSE", price: 1450.00 },
    { product: "CIGAR", price: 18150.00 },
    { product: "V & B SHORT ROBUSTO", price: 8500.00 },
    { product: "ARGYILE", price: 8500.00 },
    { product: "MULLIGHAN", price: 8500.00 },
    { product: "LA GLORIA LIGHT", price: 8500.00 },
    { product: "LA GLORIA DARK", price: 9000.00 },
    { product: "MONTERCATO", price: 16500.00 },
    { product: "CAFÉ LATTE", price: 500.00 },
    { product: "CAPPUCCINO", price: 500.00 },
    { product: "MONSTER", price: 1100.00 },
    { product: "POWER HOUSE", price: 500.00 },
    { product: "PLUG N PLAY", price: 875.00 },
    { product: "AMBER", price: 900.00 },
    { product: "POCARI SWEAT 500ML", price: 800.00 },
    { product: "POCARI SWEAT 350ML", price: 698.00 },
    { product: "POCARI SWEAT 330ML", price: 718.00 },
    { product: "AMERICANO", price: 500.00 },
    { product: "MACHIATTO", price: 500.00 },
    { product: "DOUBLE EXPRESSO", price: 600.00 },
    { product: "SINGLE EXPRESSO", price: 400.00 },
    { product: "SPARKLING WATER", price: 150.00 },
    { product: "MALTA GUINESS BOTTLE", price: 641.00 },
    { product: "CWAY", price: 160.00 },
    { product: "LASENA", price: 235.00 },
    { product: "AMSTEL CAN", price: 590.00 },
    { product: "EXOTIC", price: 1375.00 },
    { product: "FAYROUZ", price: 475.00 },
    { product: "PEPSI", price: 420.00 },
    { product: "FAYROUZ CAN", price: 475.00 },
    { product: "AQUAFINA SMALL", price: 135.00 },
    { product: "LILLY WATER", price: 162.00 },
    { product: "ZEO ORIGIN CAN", price: 350.00 },
    { product: "ZERO COKE", price: 345.00 },
    { product: "FAROUZ", price: 200.00 },
    { product: "AMSTEL", price: 560.00 },
    { product: "NESTLE ORDINARY", price: 210.00 },
    { product: "SWAN WATER", price: 165.00 },
    { product: "MALTA GUINESS CAN", price: 680.00 },
    { product: "AQUAFINA BIG", price: 177.00 },
    { product: "HOLLANDIA", price: 1850.00 },
    { product: "PET ORIGIN", price: 1050.00 },
    { product: "COKE PET", price: 450.00 },
    { product: "NBC CAN", price: 525.00 },
    { product: "CHIVITA", price: 1540.00 },
    { product: "EVA", price: 100.00 },
    { product: "BLUE LABEL", price: 458335.00 },
    { product: "MOET BRUT", price: 82300.00 },
    { product: "HENESSY VS", price: 66666.00 },
    { product: "HENESSEY VSOP", price: 112500.00 },
    { product: "CAMPARI MEDIUM", price: 17230.00 },
    { product: "CAMPARI BIG", price: 22050.00 },
    { product: "BLACKLABEL", price: 38500.00 },
    { product: "BAILEYS BIG", price: 15625.00 },
    { product: "CIROK VODKA", price: 17000.00 },
    { product: "CALYPSO", price: 4000.00 },
    { product: "GIACONDI", price: 12500.00 },
    { product: "ORIGIN BITTER PLASTIC", price: 1095.00 },
    { product: "GOLD LABEL", price: 75000.00 },
    { product: "DE MERLE", price: 22250.00 },
    { product: "GREEN LABEL", price: 37500.00 },
    { product: "GLENFIDDISH 12YRS", price: 65900.00 },
    { product: "DOUGLASS HILL", price: 5000.00 },
    { product: "GLENFIDDISH 18YRS", price: 141667.00 },
    { product: "GLENFIDDISH 15YRS", price: 93750.00 },
    { product: "L.P BRUT", price: 90625.00 },
    { product: "NEDERBURG MOTOCYCLE", price: 21875.00 },
    { product: "FREEZE", price: 4000.00 },
    { product: "ORIGIN CAN BEER", price: 350.00 },
    { product: "BLACK BARREL", price: 42710.00 },
    { product: "NEDERBURG MERLOT", price: 21875.00 },
    { product: "BOLS YOUGHURT", price: 5500.00 },
    { product: "SMALL DATE", price: 1000.00 },
    { product: "BIG DATE", price: 2000.00 },
    { product: "WILD POWER", price: 500.00 },
    { product: "SWEET LIPS", price: 8335.00 },
    { product: "MARTEL SWIFT", price: 97917.00 },
    { product: "MARTEL VS", price: 43750.00 },
    { product: "TEELING SINGLE MALT", price: 34375.00 },
    { product: "POWER GINGER", price: 700.00 },
    { product: "SINGLETON 12YRS", price: 75000.00 },
    { product: "SINGLETON 15YRS", price: 85420.00 },
    { product: "SINGLETON 18YRS", price: 147500.00 },
    { product: "ST LAUREN", price: 2500.00 },
    { product: "CHAPEAU", price: 2500.00 },
    { product: "DE ROCK", price: 500.00 },
    { product: "WEST CORK", price: 16970.00 },
    { product: "BOMBAY", price: 13125.00 },
    { product: "TEMPRANILLO BIG", price: 7500.00 },
    { product: "TEELING SINGLE GRAIN", price: 18125.00 },
    { product: "TEMPRANILLO SMALL", price: 2250.00 },
    { product: "SMOKE HEAD", price: 46145.00 },
    { product: "BULL DOG", price: 17710.00 },
    { product: "BLACK AND WHITE", price: 7300.00 },
    { product: "ELLIOT", price: 5000.00 },
    { product: "PORTA", price: 800.00 },
    { product: "MACALLAN 12YRS", price: 93750.00 },
    { product: "SWEET LIPS MEDIUM", price: 7125.00 },
    { product: "DU CAP", price: 12500.00 },
    { product: "VAJIE", price: 4000.00 },
    { product: "ROBERTSON", price: 4000.00 },
    { product: "COASTAL RIDGE", price: 3500.00 },
    { product: "FAMOUS GROUSE", price: 8125.00 },
    { product: "SKYY VODKA", price: 7500.00 },
    { product: "REMMY MARTINS VSOP", price: 85420.00 },
    { product: "IVORY CREAM", price: 1095.00 },
    { product: "TAKING ROOT", price: 11695.00 },
    { product: "PRIMA AMORE", price: 11695.00 },
    { product: "BALLANTINE", price: 13465.00 },
    { product: "FOUR COUSINS", price: 8550.00 },
    { product: "BIG SKYY VODKA", price: 11675.00 },
    { product: "CAMPARI SMALL", price: 6030.00 },
    { product: "L P DEMISEC", price: 90625.00 },
    { product: "CAPTAIN MORGAN", price: 2500.00 },
    { product: "TEEZERS", price: 525.00 },
    { product: "LABEL 5", price: 5000.00 },
    { product: "JONNY WALKER", price: 153750.00 },
    { product: "JACK DANIELS", price: 49165.00 },
    { product: "4TH STREET", price: 3985.00 },
    { product: "GORDON DRY GIN", price: 7350.00 },
    { product: "CHIVAS 15YRS", price: 48075.00 },
    { product: "JAGGERMASTER", price: 16670.00 },
    { product: "JAMESON GREEN", price: 26050.00 },
    { product: "HEINEKEN DRAUGHT", price: 1100.00 },
    { product: "OLMECA TEQUILLA", price: 17310.00 },
    { product: "OLMECA TEQUILLA", price: 21165.00 },
    { product: "CHIVAS 18YRS", price: 76925.00 },
    { product: "CHIVAS 12YRS", price: 36540.00 },
    { product: "HEINEKEN", price: 945.00 },
    { product: "DESPERADO", price: 870.00 },
    { product: "GULDER", price: 895.00 },
    { product: "SNAPP", price: 300.00 },
    { product: "ORIGIN BEER", price: 850.00 },
    { product: "JB SMALL", price: 1875.00 },
    { product: "TIGER", price: 750.00 },
    { product: "CASTLE LIGHT", price: 765.00 },
    { product: "TROPHY DRAFT", price: 450.00 },
    { product: "HEINEKEN CAN", price: 705.00 },
    { product: "ROMER", price: 2500.00 },
    { product: "TROPHY", price: 750.00 },
    { product: "SMALL STOUT", price: 881.00 },
    { product: "BIG STOUT", price: 1396.00 },
    { product: "ORIGIN BIG BOTTLE", price: 990.00 },
    { product: "SMIRN OFF ICE", price: 690.00 },
    { product: "RADLER", price: 565.00 },
    { product: "33", price: 470.00 },
    { product: "ACTION BITTER MEDIUM", price: 1585.00 },
    { product: "GORDON ORANGE", price: 7350.00 },
    { product: "LEGEND SMALL", price: 840.00 },
    { product: "STOUT CAN", price: 785.00 },
    { product: "TROPHY STOUT", price: 760.00 },
    { product: "APERITO", price: 3000.00 },
    { product: "BUDWEISER", price: 803.00 },
    { product: "SMOOTH", price: 905.00 },
    { product: "ACTION BITTER SMALL", price: 710.00 },
    { product: "SMIRNOFF ICE CAN", price: 980.00 },
    { product: "GOLDBERG BLACK", price: 1035.00 },
    { product: "STAR", price: 720.00 },
    { product: "MEDIUM STOUT", price: 1000.00 },
    { product: "ACTION BITTERS BIG", price: 2500.00 },
    { product: "CHELSEA DRY GIN", price: 885.00 },
    { product: "GOLDBERG", price: 680.00 },
    { product: "FLYING FISH", price: 610.00 },
    { product: "CHELSEA DRY GIN MEDIUM", price: 886.00 },
    { product: "WHISKEY TOT", price: 2500.00 },
    { product: "SMIRNOFF ICE BIG", price: 940.00 },
    { product: "TROPHY CAN", price: 650.00 },
    { product: "ACTION BITTERS SMALL", price: 710.00 }
  ];