import React from "react";
import { useNavigate } from "react-router-dom";

const Stores = () => {
  const navigate = useNavigate();

  return (
    <div className="p-4 md:p-6  bg-gray-800 min-h-screen">
      <div className="mb-4 md:mb-8">
        <h3 className=" text-white text-[24px] md:text-[32px] text-center font-bold mt-4">
          Select Kitchen
        </h3>
      </div>

      <ul>
        <li
          onClick={() => {
            navigate("/kitchen-details", {
              state: "SAM N EVE",
            });
          }}
          className="border-2 cursor-pointer rounded-lg bg-white h-[200px] flex justify-center flex-col items-center mb-8 md:mb-10 "
        >
          <p className="text-[20px] md:text-[28px] font-semibold text-red-500 text-cenetr">
            SAM N EVE
          </p>
          <p className="text-[18px] md:text-[24px] font-semibold text-black text-cenetr">
            Kitchen
          </p>
        </li>
        <li
          onClick={() => {
            navigate("/kitchen-details", {
              state: "Aina",
            });
          }}
        className="border-2 cursor-pointer rounded-lg bg-white h-[200px] flex justify-center flex-col items-center  mb-8 md:mb-10 ">
          <p className="text-[20px] md:text-[28px] font-semibold text-yellow-500 text-cenetr">
            AINA
          </p>
          <p className="text-[18px] md:text-[24px] font-semibold text-black text-cenetr">
            Kitchen
          </p>
        </li>
        <li
          onClick={() => {
            navigate("/kitchen-details", {
              state: "Emily",
            });
          }}
        className="border-2 cursor-pointer rounded-lg bg-white h-[200px] flex justify-center flex-col items-center  mb-8 md:mb-10 ">
          <p className="text-[20px] md:text-[28px] font-semibold text-yellow-500 text-cenetr">
            EMILY
          </p>
          <p className="text-[18px] md:text-[24px] font-semibold text-black text-cenetr">
            Kitchen
          </p>
        </li>
        <li
          onClick={() => {
            navigate("/drinks", {
              state: "Aina",
            });
          }}
          className="border-2 cursor-pointer rounded-lg bg-white h-[200px] flex justify-center flex-col items-center mb-10 ">
          <p className="text-[20px] md:text-[28px] font-semibold text-yellow-500 text-center">
            DRINKS
          </p>
         
        </li>
      </ul>
      
    </div>
  );
};

export default Stores;
