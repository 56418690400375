import { ArrowLeft } from "iconsax-react";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const KitchenDetails = () => {
  const location = useLocation();
  const kitchenName = location.state;
  return (
    <div className="p-4 md:p-6  bg-gray-800 min-h-screen">
        <Link to="/product-price" className="flex items-center gap-2 text-white mb-3"><ArrowLeft color="white"/>   Go Back</Link>
      <h1 className=" text-white text-[24px] md:text-[32px] text-center font-bold mt-4 mb-4 md:mb-8">
        Welcome to {kitchenName} Kitchen
      </h1>

      {kitchenName === "SAM N EVE" && (
        <div className="w-full">
          <img src="./sam1.jpg" alt="kitchen" className="mb-6 md:mb-8 mx-auto" />
          <img src="./sam2.jpg" alt="kitchen"  className="mx-auto" />
        </div>
      )}

      {kitchenName === "Aina" && (
        <div className="w-full">
          <img className="mx-auto"  src="./aina.jpg" alt="aina kitchen" />
        </div>
      )}
       {kitchenName === "Emily" && (
        <div className="w-full">
          <img className="mx-auto" src="./emily.jpg" alt="Emily kitchen" />
        </div>
      )}
    </div>
  );
};

export default KitchenDetails;
