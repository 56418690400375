/* eslint-disable */
import {
  ArrangeHorizontal,
  ArrowDown2,
  ArrowUp2,
  Card,
  Chart,
  EmptyWalletTime,
  SearchNormal1,
  TransmitSquare,
} from "iconsax-react";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { getProfile } from "../../utils/helperFunction";
import { useQuery } from "@tanstack/react-query";
import { NumericFormat } from "react-number-format";
import PinModal from "../../component.js/PinModal";
import PreviewModal from "../../component.js/PreviewModal";
import api from "../../api";
import { enqueueSnackbar } from "notistack";
import { Link } from "react-router-dom";

const Wallet = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.paylodeservices.com/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [isFundWallet, setIsFundWallet] = useState(false);
  const [isTransferModal, setIsTransferModal] = useState(false);
  const [transferAmount, setTransferAmount] = useState("");
  const [amount, setAmount] = useState("");
  const [fundPhase, setFundPhase] = useState(1);
  const [note, setNote] = useState("");
  const [pin, setPin] = useState("");
  const [userId, setUserId] = useState("");
  const [searchName, setSearchName] = useState("");


  const handleAmount = ()=> {
    if(!amount){
      enqueueSnackbar("Please enter an amount", { variant: "error" });
      return;
    }
    if(amount< 100){
      enqueueSnackbar("Amount should be at least NGN100", { variant: "error" });
      return;
    }
    setFundPhase(2);
  }

  const handleFundWallet = () => {
    setIsFundWallet(true);
  };
  const handleTransferModal = () => {
    setIsTransferModal(true);
  };
  const closeFundWallet = () => {
    setIsFundWallet(false);
    setFundPhase(1);
    ClearForm();
  };

  const closeTransferWallet = () => {
    setIsTransferModal(false);
    setFundPhase(1);
    ClearForm();
  };
  function ClearForm() {
    setAmount("");
    setNote("");
    setPin("");
    setUserId("");
    setFundPhase(1);
  }

  const handlePin = () => {
    setFundPhase(3);
  };
  let userData = localStorage.getItem("auth");
  let stringData = JSON.parse(userData);

  const transactionId = "someTransactId123";

  const Checkout = (transxId, kexx) => {
    closeFundWallet();

    var handler = PaylodeCheckout.setup({
      firstname: stringData?.user?.name,
      lastname: stringData?.user?.name,
      email: stringData?.user?.email,
      //   redirectUrl: "http://paylode.com",
      phonenumber: stringData?.user?.phone,
      publicKey: kexx,
      amount: amount,
      currency: "NGN",
      onClose: function (data) {
       
      },
      onSuccess: function (data) {
        console.log("Returned data:", data);
        window.location.href = `/success?transactionId=${transxId}&amount=${amount}&m_id=${ProfileData?.balance?.merchant_id}`;
      },
    });
    handler.openIframe();
  };

  async function fetchDataFromAPI(e) {
    setIsLoading(true);

    try {
      const response = await api.initiatePayment({
        merchant_id: ProfileData?.balance?.merchant_id,
      });

      setIsLoading(false);
      let transxId = response.data;
      let kexx = response.key;
      Checkout(transxId, kexx);
    } catch (e) {
      setIsLoading(false);

      enqueueSnackbar(e.message, { variant: "error" });
    }
  }

  async function handleTransfer(e) {
    setIsLoading(true);

    try {
      const response = await api.transfer({
        recipient_id: userId,
        amount,
        pin,
        note,
      });

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      closeTransferWallet();
      ProfileQuery.refetch();
    } catch (e) {
      setIsLoading(false);

      enqueueSnackbar(e.message, { variant: "error" });
    }
  }

  const ProfileQuery = useQuery(["myprofile"], () => getProfile(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const ProfileData = ProfileQuery?.data?.data || [];
  async function getTransactions(page) {
    const response = await api.getTransaction({ params: { page } });
    return response;
  }

  const TransactionQuery = useQuery(["users"], () => getTransactions(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const TrasactionData = TransactionQuery?.data?.data || [];

  const [isActive, setIsActive] = useState(false);

  return (
    <div className="p-[16px] md:px-[24px] xl:p-[32px]">
      <div className="mb-[24px] md:mb-[28px] xl:mb-[32px]">
        <h3 className="text-[18px] md:text-[24px] font-meddium text-[#25324B]">
          Wallet
        </h3>
        <p className="text-[14px] md:text-[16px] font-meddium text-[#7C8493]">
          {" "}
          Fund And Transfer From Your Wallet{" "}
        </p>
      </div>
      <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[16px] md:gap-[20px] mt-5 ">
        <li className="rounded-lg relative  bg-slate-50 overflow-hidden border-[0.8px] border-[#E4E7EC] shadow p-2 md:p-4">
          <Chart
            size="184"
            color="#E4E2DFA0"
            className="absolute bottom-0 -z-10"
          />
          <div className="flex justify-between">
            <p className="text-[14px] md:text-[16px] text-black font-meddium ">
              {" "}
              Wallet Balance
            </p>
            <button className="text-red px-2 py-1 rounded-md border bg-gray-200 self-end">
              <EmptyWalletTime size="18" color="#124072" />
            </button>
          </div>
          <div className="flex mt-6">
            <div className="border-r-[0.8px] border-[#E4E7EC] pr-2">
              {" "}
              <p className="text-[#000] font-semibold text-[20px] md:text-[32px]  ">
                <NumericFormat
                  value={ProfileData?.balance?.balance}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
          </div>
        </li>
        <button
          onClick={handleFundWallet}
          className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] shadow p-2 md:p-4"
        >
          <div className="flex justify-between">
            <p className="text-[14px] md:text-[16px] text-black font-meddium ">
              {" "}
              Fund Wallet
            </p>
            <button className="text-red px-2 py-1 rounded-md border bg-gray-200 self-end">
              <TransmitSquare size="18" color="#124072" />
            </button>
          </div>
          <div className="flex items-center gap-3 mt-6 justify-center">
            <img
              src="./asset/addWallet.png"
              alt="wallet"
              className="h-[54px] w-[43px]"
            />

            <div className="border-r-[0.8px] border-[#E4E7EC] pr-2">
              {" "}
              <p className="text-[#000] font-semibold text-[20px] md:text-[32px]  ">
                Add Money
              </p>
            </div>
          </div>
        </button>
        {/* <button
          onClick={() => setIsTransferModal(true)}
          className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] shadow p-2 md:p-4"
        >
          <div className="flex justify-between">
            <p className="text-[14px] md:text-[16px] text-black font-meddium ">
              {" "}
              Transfer to User Wallet
            </p>
            <button className="text-red px-2 py-1 rounded-md border bg-gray-200 self-end">
              <TransmitSquare size="18" color="#124072" />
            </button>
          </div>
          <div className="flex items-center gap-3 mt-6 justify-center">
            <img
              src="./asset/Withdraw.png"
              alt="wallet"
              className="h-[54px] w-[43px]"
            />

            <div className="border-r-[0.8px] border-[#E4E7EC] pr-2">
              {" "}
              <p className="text-[#000] font-semibold text-[20px] md:text-[32px]  ">
                Transfer
              </p>
            </div>
          </div>
        </button> */}
      </ul>
      <div className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] mt-10 md:mt-14">
        <div className="flex items-center justify-between bg-white p-3">
          <p className=" text-[16px] md:text-lg  text-[#000] leading-[24px] font-medium text-left ">
            Recent Funding
          </p>
          {/* <div className=" relative  mt-[16px]  flex items-center">
            <SearchNormal1 size="16" className="absolute left-[4px]" />

            <input
              type="text"
              placeholder="Search by ref number.."
              className="w-[200px] h-[40px] pl-[36px] py-[12px] text-[14px] text-[#344054] bg-[#F9FAFB] leading-[20px]  placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              autoComplete="on"
              name=""
              id=""
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div> */}
        </div>
        <div class="overflow-x-auto rounded-lg">
          <table className="min-w-full mb-6 border-[0.8px] border-r-[0.8px]  border-l-[0.8px] border-[#E4E7EC] rounded-lg">
            <thead className="bg-light-gray">
              <tr className="">
                <th
                  scope="col"
                  className=" px-2 md:px-5 border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap  gap-[6px] md:gap-[12px] items-center my-0">
                    Date
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap  gap-[6px] md:gap-[12px] items-center my-0">
                    Transaction ID
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap  gap-[6px] md:gap-[12px] items-center my-0">
                    Description
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap  gap-[6px] md:gap-[12px] items-center my-0">
                    Charge
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap gap-[6px] md:gap-[12px] items-center my-0">
                    Amount
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <div>Loading...</div>}
              {isLoading && TrasactionData?.length === 0 && (
                <tr>
                  <td className="text-center" colspan="6">
                    <img
                      src="./nodata.gif"
                      className="mx-auto mt-6 h-[70px] "
                      alt=""
                    />
                    <h3 className="text-[30px] leading-[35px]  text-[#1A202C] font-extrabold mb-[6px]">
                      No Transaction Found
                    </h3>
                  </td>
                </tr>
              )}
              {TrasactionData &&
                TrasactionData?.filter(
                  (result) => result?.description === "Fund wallet"
                ).map((result) => (
                  <tr className="mb-2 hover:bg-light-gray">
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.created_at}
                    </td>
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.product_id}
                    </td>
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-green-500 font-medium text-left  ">
                      {result?.description}
                    </td>
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.platform_charges}
                    </td>

                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      <NumericFormat
                        value={result?.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex items-center justify-between mt-5">
        <button
          className="border-[0.8px] border-[#E4E7EC] py-2 px-3 text-center w-[90px] text-sm"
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>

        <button
          // disabled={ProductQuery.length < 10}
          className="border-[0.8px] border-[#E4E7EC] py-2 px-3 text-center w-[90px] text-sm"
          onClick={() => setPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>

      <Modal
        isCentered
        isOpen={isTransferModal}
        onClose={closeTransferWallet}
        size={{ base: "xs", sm: "md", lg: "xl" }}
        style={{ borderRadius: 12 }}
        motionPreset="slideInBottom"
        className="rounded-[12px]"
      >
        <ModalOverlay />
        <ModalContent>
          {fundPhase === 1 && (
            <>
              {" "}
              <ModalHeader
                py="4"
                color="#000000"
                className="text-[18px] md:text-[20px] text-[#000000] font-medium leading-[24px] md:leading-[24px]"
              >
                Transfer To User Wallet
              </ModalHeader>
              <ModalCloseButton size={"sm"} />
              <Divider color="#98A2B3" />
              <ModalBody
                pt={{ base: "20px", md: "24px" }}
                px={{ base: "16px", md: "24px" }}
                pb={{ base: "30px", md: "40px" }}
                className="pt-[20px] md:pt-[24px] px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
              >
                <div className="my-[18px]">
                  <label className="text-[14px] text-[#667185]    mb-[8px] ">
                    User ID
                  </label>
                  <div className=" relative    flex banks-center">
                    <input
                      type="text"
                      placeholder=""
                      className="w-full h-[48px] pl-[24px] pr-[8px] py-[12px] text-[14px] text-[#344054]   placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#26ae5f] focus:border-[#26ae5f] "
                      name=""
                      id="full-name"
                      value={userId}
                      onChange={(e) => {
                        setUserId(e.target.value);
                      }}
                      autoCapitalize="off"
                      autoCorrect="off"
                      spellCheck="false"
                    />
                  </div>
                </div>
                <div className="my-[18px]">
                  <label className="text-[14px] text-[#667185]    mb-[8px] ">
                    Amount
                  </label>
                  <div className=" relative    flex banks-center">
                    <input
                      type="text"
                      placeholder=""
                      className="w-full h-[48px] pl-[24px] pr-[8px] py-[12px] text-[14px] text-[#344054]   placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#26ae5f] focus:border-[#26ae5f] "
                      name=""
                      id="full-name"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      autoCapitalize="off"
                      autoCorrect="off"
                      spellCheck="false"
                    />
                  </div>
                </div>
                <div className="my-[18px]">
                  <label className="text-[14px] text-[#667185]    mb-[8px] ">
                    Note
                  </label>
                  <div className=" relative    flex banks-center">
                    <input
                      type="text"
                      placeholder=""
                      className="w-full h-[48px] pl-[24px] pr-[8px] py-[12px] text-[14px] text-[#344054]   placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#26ae5f] focus:border-[#26ae5f] "
                      name=""
                      id="full-name"
                      value={note}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                      autoCapitalize="off"
                      autoCorrect="off"
                      spellCheck="false"
                    />
                  </div>
                </div>
              </ModalBody>
              <Divider />
              <ModalFooter gap={"16px"}>
                <button
                  onClick={closeTransferWallet}
                  className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px] py-[10px] text-[14px] font-medium text-black"
                >
                  Cancel
                </button>
                <button
                  onClick={() => setFundPhase(2)}
                  className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#124072] flex banks-center justify-center text-center rounded-[8px] py-[10px] text-[14px] font-medium text-white"
                >
                  {isLoading ? (
                    <ClipLoader color={"white"} size={20} />
                  ) : (
                    <> Send </>
                  )}
                </button>
              </ModalFooter>
            </>
          )}
          {fundPhase === 2 && (
            <>
              <PinModal
                isLoading={isLoading}
                pin={pin}
                setPin={setPin}
                handlePin={handlePin}
                onClose={closeTransferWallet}
              />
            </>
          )}
          {fundPhase === 3 && (
            <>
              <PreviewModal
                isLoading={isLoading}
                userId={userId}
                note={note}
                amount={amount}
                onClose={closeTransferWallet}
                handleTransfer={handleTransfer}
              />
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        isOpen={isFundWallet}
        onClose={closeFundWallet}
        size={{ base: "xs", sm: "md", lg: "xl" }}
        style={{ borderRadius: 12 }}
        motionPreset="slideInBottom"
        className="rounded-[12px]"
      >
        <ModalOverlay />
        <ModalContent>
          {fundPhase === 1 && (
            <>
              {" "}
              <ModalHeader
                py="4"
                color="#000000"
                className="text-[18px] md:text-[20px] text-[#000000] font-medium leading-[24px] md:leading-[24px]"
              >
                Fund Wallet
              </ModalHeader>
              <ModalCloseButton size={"sm"} />
              <Divider color="#98A2B3" />
              <ModalBody
                pt={{ base: "20px", md: "24px" }}
                px={{ base: "16px", md: "24px" }}
                pb={{ base: "30px", md: "40px" }}
                className="pt-[20px] md:pt-[24px] px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
              >
                <div className="my-[18px]">
                  <label className="text-[14px] text-[#667185]    mb-[8px] ">
                    Amount
                  </label>
                  <div className=" relative    flex banks-center">
                    <input
                      type="text"
                      placeholder=""
                      className="w-full h-[48px] pl-[24px] pr-[8px] py-[12px] text-[14px] text-[#344054]   placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#26ae5f] focus:border-[#26ae5f] "
                      name=""
                      id="full-name"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      autoCapitalize="off"
                      autoCorrect="off"
                      spellCheck="false"
                    />
                  </div>
                </div>
              </ModalBody>
              <Divider />
              <ModalFooter gap={"16px"}>
                <button
                  onClick={closeFundWallet}
                  className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px] py-[10px] text-[14px] font-medium text-black"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleAmount()}
                  className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#124072] flex banks-center justify-center text-center rounded-[8px] py-[10px] text-[14px] font-medium text-white"
                >
                  {isLoading ? (
                    <ClipLoader color={"white"} size={20} />
                  ) : (
                    <> Send </>
                  )}
                </button>
              </ModalFooter>
            </>
          )}
          {fundPhase === 2 && (
            <>
              {" "}
              <ModalHeader
                py="4"
                color="#000000"
                className="text-[18px] md:text-[20px] text-[#000000] font-medium leading-[24px] md:leading-[24px]"
              >
                Confirm
              </ModalHeader>
              <ModalCloseButton size={"sm"} />
              <Divider color="#98A2B3" />
              <ModalBody
                pt={{ base: "20px", md: "24px" }}
                px={{ base: "16px", md: "24px" }}
                pb={{ base: "30px", md: "40px" }}
                className="pt-[20px] md:pt-[24px] px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
              >
                <div className="my-[18px]">
                  <p>
                    Are you sure you want to fund your wallet with {"₦"}
                    {amount}
                  </p>
                </div>
              </ModalBody>
              <Divider />
              <ModalFooter gap={"16px"}>
                <button
                  onClick={closeFundWallet}
                  className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px] py-[10px] text-[14px] font-medium text-black"
                >
                  Cancel
                </button>
                <button
                            onClick={fetchDataFromAPI}
                            className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#124072] flex banks-center justify-center text-center rounded-[8px] py-[10px] text-[14px] font-medium text-white"
                >
                  {isLoading ? (
                    <ClipLoader color={"white"} size={20} />
                  ) : (
                    <> Send </>
                  )}
                </button>
              </ModalFooter>
            </>
          )}

          {fundPhase === 3 && (
            <>
              {" "}
              <ModalHeader
                py="4"
                color="#000000"
                className="text-[18px] md:text-[20px] text-[#000000] font-medium leading-[24px] md:leading-[24px]"
              >
                Payment Method
              </ModalHeader>
              <ModalCloseButton size={"sm"} />
              <Divider color="#98A2B3" />
              <ModalBody
                pt={{ base: "20px", md: "24px" }}
                px={{ base: "16px", md: "24px" }}
                pb={{ base: "30px", md: "40px" }}
                className="pt-[20px]  px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
              >
                <div className="">
                  <ul>
                    <li className=" mb-2 md:mb-3">
                      <button
                        onClick={() => setIsActive(!isActive)}
                        className="flex gap-2 items-center pr-2 w-full  bg-[#F7F9FC] rounded-lg"
                      >
                        <div
                          className={`$h-[32px] bg-[#E9EBFD]  w-[4px] `}
                        ></div>

                        <div
                          className={`w-full flex-1   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px]`}
                        >
                          <Card size="20" />
                          <div>Pay With Card</div>
                        </div>

                        {isActive ? (
                          <ArrowUp2 size="16" variant="Linear" color="#000" />
                        ) : (
                          <ArrowDown2 size="16" variant="Linear" color="#000" />
                        )}
                      </button>
                      {isActive && (
                        <ul className="ml-[36px] pl-[12px] py-[10px] mb-3 border-l border-[#98A2B3]/50 flex flex-col gap-[8px]">
                          <button
                            onClick={fetchDataFromAPI}
                            className={` py-[6px] px-[22px] flex items-center text-[12px] mr-2  text-semibold gap-2 rounded-md  bg-[#F7F9FC] hover:text-[#002240]    leading-[18px] md:leading-[24px]  `}
                          >
                            <img
                              src="/mastercard1.png"
                              alt="master card"
                              className="h-[17px] w-[30px]"
                            />{" "}
                            Master Card
                          </button>

                          <button
                            onClick={fetchDataFromAPI}
                            className={` py-[6px] px-[22px] flex items-center text-[12px] mr-2 text-semibold  gap-2 rounded-md bg-[#F7F9FC] hover:text-[#002240]    leading-[18px] md:leading-[24px]  `}
                          >
                            <img
                              src="/verve1.png"
                              alt="verve card"
                              className="h-[17px] w-[35px]"
                            />{" "}
                            Verve Card
                          </button>

                          <Link
                            to="https://payment.devapi.live/"
                            target="_blank"
                            className={` py-[6px] px-[22px] flex items-center text-[12px] mr-2  gap-2 rounded-md  bg-[#F7F9FC] hover:text-[#002240]    leading-[18px] md:leading-[24px] text-semibold `}
                          >
                            <img
                              src="/visa1.png"
                              alt="visa card"
                              className="h-[17px] w-[30px]"
                            />{" "}
                            Visa Card
                          </Link>
                        </ul>
                      )}
                    </li>

                    <li className="">
                      <button
                        onClick={fetchDataFromAPI}
                        className="flex gap-2 items-center pr-2 w-full  bg-[#F7F9FC] rounded-lg"
                      >
                        <div
                          className={`$h-[32px] bg-[#E9EBFD]  w-[4px] `}
                        ></div>

                        <div
                          className={`w-full flex-1   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px]`}
                        >
                          <ArrangeHorizontal size="20" />
                          <div>Pay With Bank Transfer</div>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
              </ModalBody>
              <Divider />
              <ModalFooter gap={"16px"}>
                <button
                  onClick={closeFundWallet}
                  className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px] py-[10px] text-[14px] font-medium text-black"
                >
                  Cancel
                </button>
                <button
                  onClick={fetchDataFromAPI}
                  className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#124072] flex banks-center justify-center text-center rounded-[8px] py-[10px] text-[14px] font-medium text-white"
                >
                  {isLoading ? (
                    <ClipLoader color={"white"} size={20} />
                  ) : (
                    <> Send </>
                  )}
                </button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Wallet;
