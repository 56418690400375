import React, { useState } from "react";
import { products } from "../utils/data";
import { Search } from "lucide-react";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import { ArrowLeft } from "iconsax-react";

const ProductAndPrice = () => {
  const [search, setSearch] = useState("");
  const filteredVendors = products.filter((product) =>
    product.product.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <div>
      <div className="p-4 md:p-7 lg:p-10 mb-10">
                <Link to="/product-price" className="flex items-center gap-2  mb-3"><ArrowLeft color="gray"/>   Go Back</Link>
        
        <h1 className="text-xl font-bold mb-4">Sales Product</h1>

        <div className="relative group mb-5 md:mb-8">
          <Search
            size={16}
            className="absolute left-3 top-[7px] md:top-3 text-gray-400 group-hover:text-blue-600 transition-colors"
          />
          <input
            type="text"
            placeholder="Search product..."
            className="w-full pl-10 pr-4 py-1 md:py-2 border text-gray-600 bg-transparent rounded-xl focus:ring-2 focus:ring-blue-600 focus:border-transparent transition-all"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <table className="table-auto rounded-md border-collapse border border-gray-400 w-full ">
          <thead>
            <tr>
              <th className="border border-gray-400 px-4 py-2">Product</th>
              <th className="border border-gray-400 px-4 py-2">Amount</th>
            </tr>
          </thead>
          <tbody>
            {filteredVendors.map((item, index) => (
              <tr key={index}>
                <td className="border border-gray-400 px-4 py-2 font-medium">
                  {item.product}
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  <NumericFormat
                    value={item.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductAndPrice;
